import axios from 'axios';

export class Client {
    // static baseURL = "https://p01.power-blox.cloud/api/";
    // static baseURL = "http://pbx-backend.localdev.com/api/";
    // static baseURL = "http://localhost:8000/api/";
    static baseURL = "https://api-proto.power-blox.cloud/api/";

    static injectAuthHeader() {
        if (Client.getAuthData()) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + Client.getAuthData().token;
            axios.defaults.headers.common['Accept'] = "application/json";
        }
    }

    static authdata:any = null;
    static getAuthData():any {
        if (this.authdata === null) {
            let authdatastr = localStorage.getItem("authdata");
            this.authdata = authdatastr ? JSON.parse(authdatastr) : null;
        }
        return this.authdata;
    }
    
    static isNullOrWhiteSpace(string: string): boolean {
        return (!string || /^\s*$/.test(string));
    }

    static logout() {
        localStorage.removeItem("authdata");
    }

    static saveAdminUserManagement(userid: string, data: any) {
        return new Promise((resolve, reject) => {
            let senddata: any = {};
            if (typeof(data.ownerPermission) != typeof(undefined)) {
                senddata['ownerPermission'] = data.ownerPermission;
            }
            if (typeof(data.ownerUser) != typeof(undefined)) {
                senddata['ownerUser'] = this.isNullOrWhiteSpace(data.ownerUser) ? null : `/api/users/${data.ownerUser}`;
            }
            axios.put(this.baseURL + `users/${userid}/hierarchy`, senddata)
            .then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static getAdminUserManagement() {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'users/hierarchy').then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static isAdmin() {
        return new Promise((resolve, reject) => {
            if (Client.getAuthData() && Client.getAuthData().roles.includes('ROLE_ADMIN')) {
                resolve(true);
            }
            resolve(false);
        });
    }

    static login(username: string, password: string) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'login', {
                username,
                password
            }).then((response: any) => {
                if (response.data && response.data.token) {
                    localStorage.setItem("authdata", JSON.stringify(response.data));
                    Client.injectAuthHeader();
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    static register(username: string, email: string, password: string) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'users/register', [{
                username,
                password,
                email
            }]).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static passwordReset(email: string) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'users/password_reset', {
                email: email,
            }).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static passwordResetConfirm(token: string, newpassword: string) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'users/password_reset/' + token, {
                password: newpassword,
            }).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static gateways() {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'gateways').then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static hirarchy() {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'device_groups/sharedhierarchy').then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static userrelation() {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'users/userrelation').then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static alerts() {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'alerts').then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static deleteAlert(alertId: any) {
        return new Promise((resolve, reject) => {
            axios.delete(this.baseURL + 'alerts/' + alertId).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static saveAlert(data: any) {
        return new Promise((resolve, reject) => {
            let url = this.baseURL + 'alerts';
            let postdata = {
                name: data.name,
                dataField: data.dataField,
                valueComparison: data.valueComparison,
                value: Number(data.value),
                text: data.text,
                device: this.isNullOrWhiteSpace(data.deviceId) ? null : `/api/devices/${data.deviceId}`,
                deviceGroup: this.isNullOrWhiteSpace(data.groupId) ? null : `api/device_groups/${data.groupId}`
            };
            if (data.currentAlertId) {
                // Update existing entity
                url = url + "/" + data.currentAlertId;
                axios.put(url, postdata).then(function(response: any) {
                    resolve(response.data);
                }).catch(function(error) {
                    reject(error);
                });
            } else {
                // Create new entity
                axios.post(url, postdata).then(function(response: any) {
                    resolve(response.data);
                }).catch(function(error) {
                    reject(error);
                });
            }
        });
    }

    static addGroupToGroup(groupName: string, groupId: any) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'device_groups', {
                name: groupName,
                parent: `/api/device_groups/${groupId}`,
            }).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static addBlockToGroup(macAddress: string, groupId: string, blockName: string, gatewayId: string) {
        return new Promise((resolve, reject) => {
            let senddata: any = {
                macAddress: macAddress,
                name: blockName,
                devicegroup: [`/api/device_groups/${groupId}`],
            };
            if (!this.isNullOrWhiteSpace(gatewayId)) {
                senddata['gateway'] = `/api/gateways/${gatewayId}`;
            }
            axios.post(this.baseURL + 'devices', senddata).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static addGateway(macAddress: string, blockName: string) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'gateways', {
                macAddress: macAddress,
                name: blockName,
            }).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static loadDeviceInfo(deviceId: any) {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'devices/' + deviceId).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static loadDeviceDataForProperty(deviceId: any, property: any) {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'devices/' + deviceId + "/live?property=" + property).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static loadDeviceHistoryForProperty(deviceId: any, property: any, from: Date, to: Date) {
        return new Promise((resolve, reject) => {
            let fromTimestamp = (from.getTime() / 1000).toFixed(0);
            let toTimestamp = (to.getTime() / 1000).toFixed(0);
            axios.get(this.baseURL + 'devices/' + deviceId + "/history?property=" + property + "&from=" + fromTimestamp + "&to=" + toTimestamp).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static removeDevice(deviceId: any) {
        return new Promise((resolve, reject) => {
            axios.delete(this.baseURL + 'devices/' + deviceId).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static removeGateway(gatewayId: any) {
        return new Promise((resolve, reject) => {
            axios.delete(this.baseURL + 'gateways/' + gatewayId).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static removeGroup(groupId: any) {
        return new Promise((resolve, reject) => {
            axios.delete(this.baseURL + 'device_groups/' + groupId).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static updateGroup(data: any, groupId: any) {
        return new Promise((resolve, reject) => {
            axios.put(this.baseURL + 'device_groups/' + groupId, data).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static updateDevice(data: any, deviceId: any) {
        return new Promise((resolve, reject) => {
            axios.put(this.baseURL + 'devices/' + deviceId, data).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static updateGateway(data: any, gatewayId: any) {
        return new Promise((resolve, reject) => {
            axios.put(this.baseURL + 'gateways/' + gatewayId, data).then(function(response: any) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    }
}