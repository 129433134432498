import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import './GatewaySettingsModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faNetworkWired, faFolderOpen, faPen, faCube } from '@fortawesome/free-solid-svg-icons';
import { removeGateway, updateGateway, showGatewayDetails } from '../../redux/actions/hirarchy/hirarchy';

class GatewaySettingsModal extends Component<{ showModal: boolean, showGatewayDetails: any, gatewayDetails: any, updateGateway: any, removeGateway: any }, { gatewayName: string }> {
    state = {
        gatewayName: ""
    };
    
    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.gatewayName === "" && this.props.gatewayDetails && this.props.gatewayDetails.name) {
            this.setState({
                gatewayName: this.props.gatewayDetails.name
            });
        }
    }

    close() {
        this.setState({
            gatewayName: ""
        });
        this.props.showGatewayDetails(null);
    }

    save() {
        if (!this.props.gatewayDetails.id || !this.state.gatewayName) {
            return;
        }

        this.props.updateGateway({
            name: this.state.gatewayName
        }, this.props.gatewayDetails.id);
        this.close();
    }

    remove() {
        if (!confirm("Are you sure you want to remove this Gateway?")) {
            return;
        }

        this.props.removeGateway(this.props.gatewayDetails.id);
        this.close();
    }

    render() {
        return (
            <Modal
                isOpen={this.props.showModal}
                contentLabel="Update Gateway"
                ariaHideApp={false}
            >
                {this.props.gatewayDetails && <div className="modal">
                    <div className="modal-header">
                        <div className="btn btn--black">
                            <div className="icon">
                                <FontAwesomeIcon icon={ faCube } />
                            </div>
                        </div>
                        <div className="title">{this.props.gatewayDetails && this.props.gatewayDetails.name}</div>
                        <div className="btn" onClick={() => {
                            this.close();
                        }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="edit-container">
                            <div className="inputForm">
                                <span>Rename</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={faPen} />
                                    <input type="text" value={this.state.gatewayName} onChange={(event: any) => {
                                        this.setState({ gatewayName: event.target.value });
                                    }}></input>
                                </div>
                            </div>
                        </div>
                        <div className="doneBtn">
                            <a href="#" className="button" onClick={() => {
                                this.remove();
                            }}>Remove Gateway</a>
                            <a href="#" className="button button--primary" onClick={() => {
                                this.save();
                            }}>Save</a>
                        </div>
                    </div>
                </div>}
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        showModal: (state.hirarchy.gatewayForEditModal !== null),
        gatewayDetails: state.hirarchy.gatewayForEditModal,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeGateway: (gatewayId: string) => {
            dispatch(removeGateway(gatewayId));
        },
        updateGateway: (data: any, gatewayId: string) => {
            dispatch(updateGateway(data, gatewayId));
        },
        showGatewayDetails: (config: any) => {
            dispatch(showGatewayDetails(config));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GatewaySettingsModal);
