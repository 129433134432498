import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import QrReader from 'react-qr-reader';
import Select from 'react-select';
import { faQrcode, faCheck, faPen, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { addGroupToGroup, addGateway, loadHirarchy } from '../../redux/actions/hirarchy/hirarchy';

class AddGateway extends Component<{ push: any, addGateway: any, location: any }, {}> {
    state = {
        macAddress: "",
        name: "",
    };

    componentDidMount() {
    }

    render() {
        return (
            <div className="HomePage page">
                <div className="header-box">
                    <div className="title">
                        Add new Gateway
                    </div>
                </div>
                <div className="box mt-15">
                    <div className="body">
                        <div className="inputForm">
                            <span>GATEWAY MAC ADDRESS:</span>
                            <div className="inputForm-container">
                                <FontAwesomeIcon icon={faPen} />
                                <input type="text" value={this.state.macAddress} onChange={(event: any) => {
                                    this.setState({ macAddress: event.target.value.replace(/[^a-zA-Z0-9]/g, '') });
                                }}></input>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-box mt-15">
                    <div className="title">
                        NAME
                    </div>
                </div>

                <div className="box">
                    <div className="body">
                        <div className="inputForm">
                            <span>NAME YOUR NEW GATEWAY:</span>
                            <div className="inputForm-container">
                                <FontAwesomeIcon icon={faPen} />
                                <input type="text" value={this.state.name} onChange={(event: any) => {
                                    this.setState({ name: event.target.value });
                                }}></input>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-20 pt-10">
                    <a href="#" className="button button--primary" onClick={() => {
                        let name = this.state.name;
                        let macAddress = this.state.macAddress;
                        if (name && name.length > 0 && macAddress && macAddress.length > 0) {
                            this.props.addGateway(macAddress, name);
                        }
                    }}><FontAwesomeIcon icon={faCheck} /> ADD GATEWAY</a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        flatGroupList: state.hirarchy.flatGroupList
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        push: (route: any) => {
            dispatch(push(route));
        },
        addGateway: (macAddress: string, blockName: string) => {
            dispatch(addGateway(macAddress, blockName));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddGateway);
