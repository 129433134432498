import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import './DateRangeModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { setDeviceTimeinterval, showDatePickerModal } from '../../redux/actions/device/device';
import configurationButtons from '../DeviceDateFilter/DeviceDateFilterConfig';
import * as classnames from 'classnames';

class DateRangeModal extends Component<{ showDatePickerModal: boolean, setDeviceTimeinterval: any, currentTimeInterval: any, showDatePickerModalFunc: any }, {}> {
    render() {
        return (
            <Modal
                isOpen={this.props.showDatePickerModal}
                contentLabel="Choose time interval"
                ariaHideApp={false}
            >
            <div className="modal">
                <div className="modal-header">
                    <div className="btn btn--black"></div>
                    <div className="title">Chart Range (all)</div>
                    <div className="btn" onClick={() => {
                        this.props.showDatePickerModalFunc(null);
                    }}>
                        <FontAwesomeIcon icon={ faTimes } /> 
                    </div>
                </div>
                <div className="modal-body">
                    <div className="range-tags">
                        {
                            configurationButtons.map((btn: any, index: any) => {
                                return (<div key={ index } className={classnames({
                                    "range-tag-button": true,
                                    "range-tag-button--active": (this.props.currentTimeInterval === btn.value)
                                })} onClick={() => {
                                    this.props.setDeviceTimeinterval(btn.value);
                                }}>
                                    { btn.name }
                                </div>);
                            })
                        }
                    </div>
                    <div className="doneBtn">
                        <a href="#" className="button button--primary" onClick={() => {
                            this.props.showDatePickerModalFunc(null);
                        }}>OK</a>
                    </div>
                </div>
            </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        showDatePickerModal: (state.device.currentConfiguration !== null),
        currentTimeInterval: state.device.currentTimeInterval
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setDeviceTimeinterval: (currentTimeInterval: any) => {
            dispatch(setDeviceTimeinterval(currentTimeInterval));
        },
        showDatePickerModalFunc: (config: any) => {
            dispatch(showDatePickerModal(config));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DateRangeModal);
