import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import './AddAlertModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faNetworkWired, faPen, faDatabase, faMagic, faCubes, faCube } from '@fortawesome/free-solid-svg-icons';
import { showAddAlertModal, saveAlert, deleteAlert } from '../../redux/actions/alert/alert';
import Select from 'react-select';
import DataFieldOptions from '../CustomConfigModal/DataFieldOptions';
import LimitOperations from './LimitOperations';

const initialState = {
    name: "New Alert",
    dataField: DataFieldOptions[0].value,
    valueComparison: LimitOperations[0].value,
    value: 0,
    text: "",
    deviceId: "",
    groupId: "",
    dataFieldVal: DataFieldOptions[0],
    valueComparisonSelect: LimitOperations[0],
    deviceIdVal: null,
    groupIdVal: null,
    currentAlertId: null
};

class AddAlertModal extends Component<{ showModal: boolean, showAddAlertModal: any, deleteAlert: any; flatDeviceList: any[], flatGroupList: any[], saveAlert: any, addAlertModal: any }, {}> {
    state = initialState;

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!this.state.currentAlertId && this.props.addAlertModal && this.props.addAlertModal.name) {
            
            let dataFieldVal = this.state.dataFieldVal;
            for (var i = 0; i < DataFieldOptions.length; i++) {
                if (DataFieldOptions[i].value === this.props.addAlertModal.dataField) {
                    dataFieldVal = DataFieldOptions[i];
                }
            }

            let valueComparisonSelect = this.state.valueComparisonSelect;
            for (var i = 0; i < LimitOperations.length; i++) {
                if (LimitOperations[i].value === this.props.addAlertModal.valueComparison) {
                    valueComparisonSelect = LimitOperations[i];
                }
            }

            this.setState({
                name: this.props.addAlertModal.name,
                currentAlertId: this.props.addAlertModal.id,
                dataField: this.props.addAlertModal.dataField,
                valueComparison: this.props.addAlertModal.valueComparison,
                value: this.props.addAlertModal.value,
                text: this.props.addAlertModal.text,
                dataFieldVal: dataFieldVal,
                valueComparisonSelect: valueComparisonSelect,
                deviceId: this.props.addAlertModal.device == null ? null : this.props.addAlertModal.device.id,
                groupId: this.props.addAlertModal.deviceGroup == null ? null : this.props.addAlertModal.deviceGroup.id,
            });
        }
    }

    /**
     * Close
     */
    close() {
        this.setState(initialState);
        this.props.showAddAlertModal(null);
    }

    /**
     * Save
     */
    save() {
        this.props.saveAlert({
            name: this.state.name,
            dataField: this.state.dataField,
            valueComparison: this.state.valueComparison,
            value: this.state.value,
            text: this.state.text,
            deviceId: this.state.deviceId,
            groupId: this.state.groupId,
            currentAlertId: this.state.currentAlertId
        });
        this.close();
    }

    /**
     * Main render function
     */
    render() {
        return (
            <Modal
                isOpen={this.props.showModal}
                contentLabel="Custom configuration modal"
                ariaHideApp={false}
            >
                <div className="modal">
                    <div className="modal-header">
                        <div className="btn btn--black">
                            <div className="icon">
                                <FontAwesomeIcon icon={faNetworkWired} />
                            </div>
                        </div>
                        <div className="title">{this.state.name}</div>
                        <div className="btn" onClick={() => {
                            this.close();
                        }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="edit-container">
                            <div className="inputForm inputForm--lined">
                                <span>{(this.state.currentAlertId == null) ? "Name" : "Rename"}</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={faPen} />
                                    <input type="text" value={this.state.name} onChange={(event: any) => {
                                        this.setState({ name: event.target.value });
                                    }}></input>
                                </div>
                            </div>
                            <div className="inputForm inputForm--lined">
                                <span>Choose Data Field</span>
                                <div className="inputForm-container">
                                    <div style={{marginRight: '13px'}}><FontAwesomeIcon icon={faDatabase} /></div>
                                    <div style={{flexGrow: 1}}>
                                        <Select
                                            menuPlacement="auto"
                                            menuPosition="fixed"
                                            onChange={(selectedOption) => {
                                                this.setState({
                                                    dataFieldVal: selectedOption,
                                                    dataField: (selectedOption as any).value
                                                });
                                            }}
                                            value={this.state.dataFieldVal}
                                            options={DataFieldOptions}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                border: 'none',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eee',
                                                    primary: '#ed1c24',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="inputForm inputForm--lined">
                                <span>Choose Limit Logic</span>
                                <div className="inputForm-container">
                                    <div style={{marginRight: '13px'}}><FontAwesomeIcon icon={faMagic} /></div>
                                    <div style={{flexGrow: 1}}>
                                        <Select
                                            onChange={(selectedOption) => {
                                                this.setState({
                                                    valueComparisonSelect: selectedOption,
                                                    valueComparison: (selectedOption as any).value
                                                });
                                            }}
                                            value={this.state.valueComparisonSelect}
                                            options={LimitOperations}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                border: 'none',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eee',
                                                    primary: '#ed1c24',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="inputForm inputForm--lined">
                                <span>Set Limit Value</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={faPen} />
                                    <input type="number" step="any" value={this.state.value} onChange={(event: any) => {
                                        this.setState({ value: event.target.value });
                                    }}></input>
                                </div>
                            </div>
                            <div className="inputForm inputForm--lined">
                                <span>Set Custom Alert Text</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={faPen} />
                                    <input type="text" value={this.state.text} onChange={(event: any) => {
                                        this.setState({ text: event.target.value });
                                    }}></input>
                                </div>
                            </div>
                            {this.state.groupId === "" && !this.state.currentAlertId && <div className="inputForm inputForm--lined">
                                <span>Apply to Single Device</span>
                                <div className="inputForm-container">
                                <div style={{marginRight: '13px'}}><FontAwesomeIcon icon={faCube} /></div>
                                    <div style={{flexGrow: 1}}>
                                        <Select
                                            onChange={(selectedOption) => {
                                                this.setState({
                                                    deviceIdVal: selectedOption,
                                                    deviceId: (selectedOption as any).value
                                                });
                                            }}
                                            value={this.state.deviceIdVal}
                                            options={this.props.flatDeviceList}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                border: 'none',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eee',
                                                    primary: '#ed1c24',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>}
                            {this.state.deviceId === "" && !this.state.currentAlertId && <div className="inputForm inputForm--lined">
                                <span>Apply to Group</span>
                                <div className="inputForm-container">
                                    <div style={{marginRight: '13px'}}><FontAwesomeIcon icon={faCubes} /></div>
                                    <div style={{flexGrow: 1}}>
                                        <Select
                                            onChange={(selectedOption) => {
                                                this.setState({
                                                    groupIdVal: selectedOption,
                                                    groupId: (selectedOption as any).value
                                                });
                                            }}
                                            value={this.state.groupIdVal}
                                            options={this.props.flatGroupList}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                border: 'none',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eee',
                                                    primary: '#ed1c24',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="doneBtn mt-0 pt-0">
                            {this.state.currentAlertId && <a href="#" className="button" onClick={() => {
                                this.props.deleteAlert(this.state.currentAlertId);
                                this.close();
                            }}>Remove alert</a>}
                            <a href="#" className="button button--primary" onClick={() => {
                                this.save();
                            }}>Save</a>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        showModal: (state.alert.addAlertModal !== null),
        addAlertModal: state.alert.addAlertModal,
        flatGroupList: state.hirarchy.flatGroupList,
        flatDeviceList: state.hirarchy.flatDeviceList
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showAddAlertModal: (data: any) => {
            dispatch(showAddAlertModal(data));
        },
        deleteAlert: (alertId: any) => {
            dispatch(deleteAlert(alertId));
        },
        saveAlert: (data: any) => {
            dispatch(saveAlert(data));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAlertModal);
