import { HIRARCHY_LOADED, PUSH_GROUP, POP_GROUP, HIRARCHY_STARTED_LOADING, SET_ADD_TO_GROUP, GROUP_ADDED, ADDING_GROUP, UPDATING_GROUP, SHOW_GROUP_DETAILS, SHOW_DEVICE_DETAILS, UPDATING_DEVICE, GATEWAYS_STARTED_LOADING, GATEWAYS_LOADED, SHOW_GATEWAY_DETAILS, UPDATING_GATEWAY, USER_PERMISSIONS_LOADED, loadUserPermission } from "../../actions/hirarchy/hirarchy";

/**
 * Helper to find a group in the stack.
 * @param group 
 * @param id 
 */
function findGroupWithId(group: any, groupId: any): any {
    if (group.id === groupId) {
        return group;
    }

    if (group.childs && group.childs.length > 0) {
        for (let i = 0; i < group.childs.length; i++) {
            let result = findGroupWithId(group.childs[i], groupId);
            if (result) {
                return result;
            }
        }
    }
    return null;
}

/**
 * Helper to update a group based on a given data object.
 * @param group 
 * @param id 
 */
function updateGroupWithData(group: any, targetGroupId: any, data: any) {
    if (group.id === targetGroupId) {
        // Update data.
        let keys = Object.keys(data);
        for(let j = 0; j < keys.length; j++) {
            group[keys[j]] = data[keys[j]];
        }
    }

    for (let i = 0; i < group.childs.length; i++) {
        updateGroupWithData(group.childs[i], targetGroupId, data);
    }
}

/**
 * Helper to update a device based on a given data object, throughout the whole tree.
 * @param group 
 * @param id 
 */
function updateDeviceWithData(group: any, targetDeviceId: any, data: any) {
    if (group.devices) {
        for (let i = 0; i < group.devices.length; i++) {
            let device = group.devices[i];
            if (device.id === targetDeviceId) {
                // Update data.
                let keys = Object.keys(data);
                for(let j = 0; j < keys.length; j++) {
                    device[keys[j]] = data[keys[j]];
                }
            }
        }
    }

    if (group.childs) {
        for (let i = 0; i < group.childs.length; i++) {
            updateDeviceWithData(group.childs[i], targetDeviceId, data);
        }
    }
}

function updateGatewaysDeviceWithData(gateways: any, targetDeviceId: any, data: any) {
    for (let k = 0; k < gateways.length; k++) {
        let gateway = gateways[k];

        for (let i = 0; i < gateway.devices.length; i++) {
            let device = gateway.devices[i];
            if (device.id === targetDeviceId) {
                // Update data.
                let keys = Object.keys(data);
                for(let j = 0; j < keys.length; j++) {
                    device[keys[j]] = data[keys[j]];
                }
            }
        }
    }
}

function updateGatewaysWithData(gateways: any, targetGatewayId: any, data: any) {
    for (let k = 0; k < gateways.length; k++) {
        let gateway = gateways[k];
        if (gateway.id === targetGatewayId) {
            // Update data.
            let keys = Object.keys(data);
            for(let j = 0; j < keys.length; j++) {
                gateway[keys[j]] = data[keys[j]];
            }
        }
    }
}

/**
 * Helper to create a flat group list for flat device list.
 * @param group 
 */
function createFlatGroupListForDevices(group: any): any {
    let groupsArr: any[] = [];

    groupsArr.push({
        value: group.id,
        label: group.name,
        devices: group.devices
    });

    if (group.childs && group.childs.length > 0) {
        for (let i = 0; i < group.childs.length; i++) {

            let result = createFlatGroupListForDevices(group.childs[i]);
            if (result) {
                groupsArr.push(...result);
            }
        }
    }
    return groupsArr;
}

/**
 * Helper to create a flat group list.
 * @param group 
 */
function createFlatGroupList(group: any): any {
    let groupsArr: any[] = [];

    if (group.id != null) {
        groupsArr.push({
            value: group.id,
            label: group.name,
            devices: group.devices
        });
    }

    if (group.childs && group.childs.length > 0) {
        for (let i = 0; i < group.childs.length; i++) {

            let result = createFlatGroupList(group.childs[i]);
            if (result) {
                groupsArr.push(...result);
            }
        }
    }
    return groupsArr;
}

/**
 * Helper to create a flat device list.
 * @param flatGroupList 
 */
function createFlatDeviceList(flatGroupList: any): any {
    let deviceList: any[] = [];

    for (let i = 0; i < flatGroupList.length; i++) {
        let group = flatGroupList[i];
        for (let ii = 0; ii < group.devices.length; ii++) {
            let device = group.devices[ii];
            if (device) {
                deviceList.push({
                    value: device.id,
                    label: device.name,
                });
            }
        }
    }

    deviceList = deviceList.filter((device: any, index, self) =>
        index === self.findIndex((t: any) => (
            t.id === device.id
        ))
    );

    return deviceList;
}

const initialState = {
    loading: true,
    dataMutationLoading: false,
    rootNode: {},
    gateways: [],
    currentNode: {},
    hirarchyStack: [],
    currentNodeIsRootNode: true,
    addGroupToGroupId: -1,
    flatGroupList: [],
    flatDeviceList: [],
    permissionList: [],
    groupForEditModal: null,
    deviceForEditModal: null,
    gatewayForEditModal: null,
};

export default function hirarchy(state = initialState, action: any) {
    if (action.type === SET_ADD_TO_GROUP) {
        return Object.assign({}, state, {
            addGroupToGroupId: action.groupId
        });
    }
    if (action.type === HIRARCHY_STARTED_LOADING) {
        return Object.assign({}, state, {
            loading: true
        });
    }
    if (action.type === HIRARCHY_LOADED) {
        // Reload vs. first time.
        let currentNode = action.rootNode;
        let hirarchyStack = [action.rootNode.id];
        if (state.hirarchyStack.length > 0) {
            hirarchyStack = state.hirarchyStack;
            let currentNodeId = hirarchyStack[hirarchyStack.length - 1];
            currentNode = findGroupWithId(state.rootNode, currentNodeId);
        }

        // Create group list.
        let flatGroupList = createFlatGroupList(action.rootNode);
        let flatGroupListForDevices = createFlatGroupListForDevices(action.rootNode);
        let flatDeviceList = createFlatDeviceList(flatGroupListForDevices);

        // Check if we are at the root level.
        let currentNodeIsRootNode = false;
        if (hirarchyStack.length == 1) {
            currentNodeIsRootNode = true;
        }

        return Object.assign({}, state, {
            loading: false,
            rootNode: action.rootNode,
            currentNode: currentNode,
            hirarchyStack: hirarchyStack,
            currentNodeIsRootNode: currentNodeIsRootNode,
            addGroupToGroupId: action.rootNode.id,
            flatGroupList: flatGroupList,
            flatDeviceList: flatDeviceList
        });
    }
    if (action.type === USER_PERMISSIONS_LOADED) {
        return Object.assign({}, state, {
            permissionList: action.permissions,
        });
    }
    if (action.type === GATEWAYS_STARTED_LOADING) {
        return Object.assign({}, state, {
            loading: true
        });
    }
    if (action.type === GATEWAYS_LOADED) {
        return Object.assign({}, state, {
            loading: false,
            gateways: action.gateways
        });
    }
    if (action.type === ADDING_GROUP) {
        return Object.assign({}, state, {
            dataMutationLoading: true
        });
    }
    if (action.type === GROUP_ADDED) {
        return Object.assign({}, state, {
            dataMutationLoading: false
        });
    }
    if (action.type === SHOW_DEVICE_DETAILS) {
        return Object.assign({}, state, {
            deviceForEditModal: action.device
        });
    }
    if (action.type === SHOW_GATEWAY_DETAILS) {
        return Object.assign({}, state, {
            gatewayForEditModal: action.gateway
        });
    }
    if (action.type === SHOW_GROUP_DETAILS) {
        return Object.assign({}, state, {
            groupForEditModal: action.group
        });
    }
    if (action.type === PUSH_GROUP) {
        return Object.assign({}, state, {
            loading: false,
            currentNode: action.group,
            hirarchyStack: [...state.hirarchyStack, action.group.id],
            currentNodeIsRootNode: false
        });
    }
    if (action.type === POP_GROUP) {
        // Don't pop if we're at the root element.
        if (state.hirarchyStack.length <= 1) {
            return state;
        }

        // Pop top item and set new top item as currentNode.
        let hirarchyStack = [...state.hirarchyStack];
        hirarchyStack.pop();
        let currentNodeIsRootNode = false;
        if (hirarchyStack.length == 1) {
            currentNodeIsRootNode = true;
        }
        let currentNodeId = hirarchyStack[hirarchyStack.length - 1];
        let currentNode = findGroupWithId(state.rootNode, currentNodeId);
        return Object.assign({}, state, {
            loading: false,
            currentNode: currentNode,
            hirarchyStack: hirarchyStack,
            currentNodeIsRootNode
        });
    }
    if (action.type === UPDATING_GROUP) {
        // Update current node.
        let currentNode = Object.assign({}, state.currentNode);
        updateGroupWithData(currentNode, action.groupId, action.data);

        // Update root node.
        let rootNode = Object.assign({}, state.rootNode);
        updateGroupWithData(rootNode, action.groupId, action.data);
        
        return Object.assign({}, state, {
            loading: false,
            currentNode: currentNode,
            rootNode: rootNode
        });
    }
    if (action.type === UPDATING_DEVICE) {
        // Update current node.
        let currentNode = Object.assign({}, state.currentNode);
        updateDeviceWithData(currentNode, action.deviceId, action.data);

        // Update root node.
        let rootNode = Object.assign({}, state.rootNode);
        updateDeviceWithData(rootNode, action.deviceId, action.data);

        // Update gateways devices
        let gateways = [].concat(state.gateways);
        updateGatewaysDeviceWithData(gateways, action.deviceId, action.data);
        
        return Object.assign({}, state, {
            loading: false,
            currentNode: currentNode,
            rootNode: rootNode,
            gateways: gateways,
        });
    }
    if (action.type === UPDATING_GATEWAY) {
        // Update gateways
        let gateways = [].concat(state.gateways);
        updateGatewaysWithData(gateways, action.gatewayId, action.data);
        
        return Object.assign({}, state, {
            loading: false,
            gateways: gateways,
        });
    }
    return state;
}  