import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootReducer from "../reducers/index";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (history) => createStore(
    rootReducer(history),
    composeEnhancer(
        applyMiddleware(
            thunk,
            routerMiddleware(history)
        ),
    )
);