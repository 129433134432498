import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateGroup, showDeviceDetails } from '../../redux/actions/hirarchy/hirarchy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPen, faCube, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as classnames from 'classnames';
import dotsIcon from '../../assets/icons/dots.svg';
import { push } from 'connected-react-router';

class GroupItem extends Component<{ device: any, permissionList: any, push: any, updateGroup: any, showDeviceDetails: any }, { toggleOptions: boolean, deviceName: string }> {
    state = {
        toggleOptions: false,
        deviceName: ""
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.deviceName === "" && this.state.deviceName === "") {
            this.setState({
                deviceName: this.props.device.name
            });
        }
    }

    hasEditPremissions() {
        let permission = this.props.permissionList.find((per: any) => { return per.userId == this.props.device.owner.id});
        if (permission != null) {
            return permission.permission >= 2;
        } else {
            return false;
        }
    }

    render() {
        return (
            <div className="box" onClick={() => {
                this.props.push("device/" + this.props.device.id);
            }}>
                <div className="row">
                    <div className="icon">
                        <FontAwesomeIcon icon={faCube} />
                    </div>
                    <div className="title">
                        {this.props.device.name}
                    </div>
                    { this.hasEditPremissions() &&
                    <div className={classnames({
                        "action-button": true,
                        "action-button--active": this.state.toggleOptions
                    })} onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        this.props.showDeviceDetails(this.props.device);
                    }}>
                        <img className="more-icon" src={dotsIcon} />
                    </div> }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        permissionList: state.hirarchy.permissionList,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateGroup: (data: any, groupId: string) => {
            dispatch(updateGroup(data, groupId));
        },
        push: (route: any) => {
            dispatch(push(route));
        },
        showDeviceDetails: (config: any) => {
            dispatch(showDeviceDetails(config));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupItem);
