import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import SortableTree, { removeNodeAtPath } from 'react-sortable-tree';
import Modal from 'react-modal';
// @ts-ignore
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import 'react-sortable-tree/style.css';
import './Admin.scss';
import { checkAdminState, getAdminUserManagement, setUserAdminData } from '../../redux/actions/user/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faNetworkWired, faTimes, faPen } from '@fortawesome/free-solid-svg-icons';

class Admin extends Component<{ push: any, showAddAlertModal: any, setUserAdminData: any, newTreeData: any, getAdminUserManagement: any, checkAdminState: any, loadAlerts: any, alerts: any, loadHirarchy: any, loading: boolean }, { treeData: any }> {
    state = {
        treeData: null,
    }

    /**
     * Component did load.
     */
    componentDidMount() {
        this.props.checkAdminState();
        this.props.getAdminUserManagement();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        // map props.newTreeData into state.treeData so this stuff can manipulate it
        if (this.state.treeData == null && this.props.newTreeData != null) {
            this.setState({treeData: makeTreeData(this.props.newTreeData, null)});
        }
    }

    updateUser(userid: string, newdata: any) {
        this.props.setUserAdminData(userid, newdata);
    }

    /**
     * Main render method
     */
    render() {
        return (
            <div className="HomePage page">
                <div className="header-box mt-15"><div className="title">Admin functions</div></div>
                <div className="subtitle">USERS</div>
                <div className="tree-container">
                    <SortableTree
                        // theme={FileExplorerTheme}
                        treeData={this.state.treeData || []}
                        onChange={treeData => this.setState({ treeData })}
                        getNodeKey={(nodedata:any) => nodedata.node.userdata.id}
                        onMoveNode={(data: any) => {
                            let newparent = data.nextParentNode != null ? data.nextParentNode.userdata.id : null;
                            this.updateUser(data.node.userdata.id, {
                                ownerUser: newparent,
                                ownerPermission: 1
                            });
                            // TODO: somehow manage to refresh the component
                        }}
                        generateNodeProps={(rowInfo: any) => {
                            let nodeprops: any = {
                                buttons: [
                                    <div className={"button change-permission-button " + (rowInfo.node.userdata.ownerPermission == 2 ? 'active' : '')} onClick={(event) => {
                                        this.updateUser(rowInfo.node.userdata.id, {
                                            ownerPermission: 2
                                        });
                                        // TODO: somehow manage to refresh the component
                                        rowInfo.node.userdata.ownerPermission = 2; // Hack to force component update
                                        this.setState({});
                                    }}>Manager</div>
                                    ,
                                    <div className={"button change-permission-button " + (rowInfo.node.userdata.ownerPermission == 1 ? 'active' : '')} onClick={(event) => {
                                        this.updateUser(rowInfo.node.userdata.id, {
                                            ownerPermission: 1
                                        });
                                        // TODO: somehow manage to refresh the component
                                        rowInfo.node.userdata.ownerPermission = 1; // Hack to force component update
                                        this.setState({});
                                    }}>User</div>
                                ]
                            }
                            return nodeprops;
                        }}
                    />
                </div>
            </div>
        );
    }
}

const makeTreeData = (userarray: any, parentid: any) => {
    let newdataarray: any = [];
    let permisions: any = {
        0: 'None',
        1: 'User',
        2: 'Manager',
    };
    for (let i = 0; i < userarray.length; i++) {
        const userdata = userarray[i];
        let newdata = {
            title: userdata.username,
            // subtitle: permisions[userdata.ownerPermission],
            children: makeTreeData(userdata.childUsers, userdata.id),
            parentid: parentid,
            userdata: userdata,
            expanded: true,
        };
        newdataarray.push(newdata);
    }
    return newdataarray;
};

const mapStateToProps = (state: any) => {
    return {
        newTreeData: state.user.adminUserData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        push: (path: any) => {
            dispatch(push(path));
        },
        checkAdminState: () => {
            dispatch(checkAdminState());
        },
        getAdminUserManagement: () => {
            dispatch(getAdminUserManagement());
        },
        setUserAdminData: (userid: string, data: any) => {
            dispatch(setUserAdminData(userid, data));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Admin);
