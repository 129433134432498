import React, { Component } from "react";
import { connect } from 'react-redux';
import bell from '../../assets/icons/bell.svg';
import './AlertItem.scss';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showAddAlertModal } from "../../redux/actions/alert/alert";
import dotsIcon from '../../assets/icons/dots.svg';

class AlertItem extends Component<{ alert: any, showAddAlertModal: any }, {}> {

    /**
     * Main render function
     */
    render() {
        return (
            <div className="alert-item">
                <img className="icon" src={bell} />
                <div className="title">{this.props.alert.name}</div>
                <div className="action" onClick={() => {
                    this.props.showAddAlertModal(this.props.alert);
                }}>
                    <img className="more-icon" src={ dotsIcon } />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showAddAlertModal: (data: any) => {
          dispatch(showAddAlertModal(data));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertItem);
