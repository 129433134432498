import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSortAlphaDown, faFolderOpen, faPlus, faSignOutAlt, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import { loadGateways, pushGroup, popGroup, setAddToGroup, showGroupDetails, loadUserPermission } from '../../redux/actions/hirarchy/hirarchy';
import GroupItem from '../../components/GroupItem/GroupItem';
import LoadingSpinner from '../../components/Loading/Loading';
import { push } from 'connected-react-router';
import DeviceItem from '../../components/DeviceItem/DeviceItem';
import GroupSettingsModal from '../../components/GroupSettingsModal/GroupSettingsModal';
import { logout, checkAdminState } from '../../redux/actions/user/user';
import backIcon from '../../assets/icons/back.svg';
import DeviceSettingsModal from '../../components/DeviceSettingsModal/DeviceSettingsModal';
import dotsIcon from '../../assets/icons/dotsRed.svg';
import GatewayItem from '../../components/GatewayItem/GatewayItem';
import GatewaySettingsModal from '../../components/GatewaySettingsModal/GatewaySettingsModal';

class Gateways extends Component<{ loadGateways: any, loadUserPermission: any, showGroupDetails: any, setAddToGroup: any, logout: any, popGroup: any, gateways: any, loading: boolean, push: any }, {}> {
    state = {
        username: "",
        password: "",
        sort: "down"
    };

    componentDidMount() {
        this.props.loadGateways();
        this.props.loadUserPermission();
    }

    renderLoading() {
        return (
            <div>
                <div className="header-box">
                    <div className="title">
                        My Gateways
                    </div>
                </div>
                <div>
                    <div className="p-20">
                        <LoadingSpinner></LoadingSpinner>
                    </div>
                </div>
            </div>
        );
    }

    renderHirarchy() {
        return (
            <div>
                <div className="header-box">
                    <div className="title">
                        Gateways
                    </div>
                </div>
                {(this.props.gateways.length > 0) && <div className="subtitle">
                    Gateways
                </div>}
                {
                    this.props.gateways && this.props.gateways.map((gateway: any) => {
                        return (
                            <GatewayItem gateway={gateway} key={gateway.name}></GatewayItem>
                        );
                    })
                }
                <DeviceSettingsModal></DeviceSettingsModal>
                <GatewaySettingsModal></GatewaySettingsModal>
            </div>
        );
    }

    render() {
        return (
            <div className="HomePage page">
                {this.props.loading ? this.renderLoading() : this.renderHirarchy()}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.hirarchy.loading,
        gateways: state.hirarchy.gateways,
        permissionList: state.hirarchy.permissionList,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadGateways: () => {
            dispatch(loadGateways());
        },
        loadUserPermission: () => {
            dispatch(loadUserPermission());
        },
        pushGroup: (group: any) => {
            dispatch(pushGroup(group));
        },
        popGroup: () => {
            dispatch(popGroup());
        },
        setAddToGroup: (groupId: any) => {
            dispatch(setAddToGroup(groupId));
        },
        push: (path: string, state: any) => {
            dispatch(push(path, state))
        },
        logout: () => {
            dispatch(logout());
        },
        showGroupDetails: (config: any) => {
            dispatch(showGroupDetails(config));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Gateways);
