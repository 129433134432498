import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import QrReader from 'react-qr-reader';
import Select from 'react-select';
import { faQrcode, faCheck, faPen, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { addGroupToGroup, addBlockToGroup, loadHirarchy, loadGateways } from '../../redux/actions/hirarchy/hirarchy';

class AddDevice extends Component<{ push: any, addBlockToGroup: any, loadHirarchy: any, loadGateways: any, flatGroupList: any[], gatewayList: any[], location: any }, {}> {
    state = {
        macAddress: "",
        name: "",
        groupToAddTo: null,
        gatewayToAddTo: null,
        cameraDidError: false
    };

    componentDidMount() {
        this.props.loadHirarchy();
        this.props.loadGateways();

        if (this.props.location.state && this.props.location.state.selectedGroup) {
            this.setState({
                groupToAddTo: this.props.location.state.selectedGroup
            });
        }
    }

    render() {
        return (
            <div className="HomePage page">
                <div className="header-box">
                    <div className="title">
                        Add new Blox
                    </div>
                </div>

                {
                    (!this.state.cameraDidError && this.state.macAddress === "") && <div className="box">
                        <div className="body">
                            <div className="inputForm">
                                <QrReader
                                    delay={300}
                                    onError={(error) => {
                                        this.setState({
                                            cameraDidError: true
                                        });
                                    }}
                                    onScan={(data: any) => {
                                        if (data) {
                                            // alert(data);
                                            var regex = /M:(.{12})/;
                                            data = regex.exec(data);
                                            // data with regex manipulation => mac addrr

                                            this.setState({
                                                macAddress: data[1]
                                            });
                                        }
                                    }}
                                    style={{ width: '100%' }}
                                />
                                <span>SCAN THE QR CODE ON THE BACKSIDE OF YOUR POWER-BLOX</span>
                            </div>
                        </div>
                    </div>
                }

                <div className="box mt-15">
                    <div className="body">
                        <div className="inputForm">
                            <span>OR ENTER MAC ADDRESS MANUALLY:</span>
                            <div className="inputForm-container">
                                <FontAwesomeIcon icon={faPen} />
                                <input type="text" value={this.state.macAddress} onChange={(event: any) => {
                                    this.setState({ macAddress: event.target.value.replace(/[^a-zA-Z0-9]/g, '') });
                                }}></input>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-box mt-15">
                    <div className="title">
                        NAME AND GROUP
                    </div>
                </div>

                <div className="box">
                    <div className="body">
                        <div className="inputForm">
                            <span>NAME YOUR NEW POWER-BLOX:</span>
                            <div className="inputForm-container">
                                <FontAwesomeIcon icon={faPen} />
                                <input type="text" value={this.state.name} onChange={(event: any) => {
                                    this.setState({ name: event.target.value });
                                }}></input>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box mt-5">
                    <div className="body">
                        <div className="inputForm inputFormSelect">
                            <span>QUICKADD IT TO A GROUP:</span>
                            <div style={{flexGrow: 1}}>
                                <Select
                                    onChange={(selectedOption) => {
                                        this.setState({
                                            groupToAddTo: selectedOption
                                        });
                                    }}
                                    value={this.state.groupToAddTo}
                                    options={this.props.flatGroupList}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        border: 'none',
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#eee',
                                            primary: '#ed1c24',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="box mt-5">
                    <div className="body">
                        <div className="inputForm inputFormSelect">
                            <span>QUICKADD IT TO A GATEWAY:</span>
                            <div style={{flexGrow: 1}}>
                                <Select
                                    onChange={(selectedOption) => {
                                        this.setState({
                                            gatewayToAddTo: selectedOption
                                        });
                                    }}
                                    value={this.state.gatewayToAddTo}
                                    options={this.props.gatewayList}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        border: 'none',
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#eee',
                                            primary: '#ed1c24',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                                </div>*/}

                <div className="p-20 pt-10">
                    <a href="#" className="button button--primary" onClick={() => {
                        let groupId = null;
                        if (this.state.groupToAddTo) {
                            groupId = (this.state.groupToAddTo as any).value;
                        }
                        let gatewayId = null;
                        if (this.state.gatewayToAddTo) {
                            gatewayId = (this.state.gatewayToAddTo as any).value;
                        }
                        let name = this.state.name;
                        let macAddress = this.state.macAddress;
                        if (groupId && name && name.length > 0 && macAddress && macAddress.length > 0) {
                            this.props.addBlockToGroup(macAddress, groupId, name, gatewayId);
                        }
                    }}><FontAwesomeIcon icon={faCheck} /> ADD POWER-BLOX TO MY BLOX</a>
                </div>
            </div>
        );
    }
}

const makeGatewayList = (gateways: any) => {
    let gatewayList: any[] = [];
    for (var i = 0; i < gateways.length; i++) {
        let gateway = gateways[i];
        gatewayList.push({
            value: gateway.id,
            label: gateway.name,
        });
    }
    return gatewayList;
}

const mapStateToProps = (state: any) => {
    return {
        flatGroupList: state.hirarchy.flatGroupList,
        gatewayList: makeGatewayList(state.hirarchy.gateways),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        push: (route: any) => {
            dispatch(push(route));
        },
        loadHirarchy: () => {
            dispatch(loadHirarchy());
        },
        loadGateways: () => {
            dispatch(loadGateways());
        },
        addBlockToGroup: (macAddress: string, groupIdToAddTo: string, blockName: string, gatewayToAddTo: string) => {
            dispatch(addBlockToGroup(macAddress, groupIdToAddTo, blockName, gatewayToAddTo));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDevice);
