import React, { Component } from 'react';
import logo from '../../assets/img/logo.png';
import logoshape from '../../assets/img/logoshape.png';
import { connect } from 'react-redux';
import { performLogin, performPasswordResetConfirm } from '../../redux/actions/user/user';
import LoadingSpinner from '../../components/Loading/Loading';
import { push } from 'connected-react-router';
import ChartDiagramm from '../../components/ChartDiagramm/ChartDiagramm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Client } from '../../services/client';

class ChangePassword extends Component<{ match: any, performPasswordResetConfirm: any, loading: boolean, loginError: string, push: any }, { token: string, newpassword: string }> {
    state = {
        token: "",
        newpassword: "",
    };

    renderLoading() {
        return (
            <LoadingSpinner></LoadingSpinner>
        );
    }

    componentDidMount() {
        this.setState({token: this.props.match.params.token});
        
        if (Client.getAuthData()) {
            this.props.push("overview");
        }
    }

    resetPassword() {
        this.props.performPasswordResetConfirm(this.state.token, this.state.newpassword);
    }

    renderLoginMask() {
        return (
            <div className="column">
                <div className="box">
                    <div className="header">Reset Password</div>
                    <div className="body">
                        <div className="inputForm inputForm--underlined">
                            <span>New Password</span>
                            <div className="inputForm-container">
                                <FontAwesomeIcon icon={faLock} />
                                <input type="password" onChange={(event: any) => {
                                    this.setState({ newpassword: event.target.value });
                                }}></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-container">
                    {this.props.loginError && <div className="errorMessage">{this.props.loginError}</div>}
                    <a href="#" className="button button--primary" onClick={() => {
                        this.resetPassword();
                    }}>Change Password</a>
                    <a className="button" href="/">Back to Login</a>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="LoginFrontPage page">
                <img src={logoshape} className="logoshape" />
                <img src={logo} className="logo" />
                {this.props.loading ? this.renderLoading() : this.renderLoginMask()}
            </div>
        );
    }
}


const mapStateToProps = (state: any) => {
    return {
        loading: state.user.loading,
        loginError: state.user.loginError
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        performPasswordResetConfirm: (token: string, username: string) => {
            dispatch(performPasswordResetConfirm(token, username));
        },
        push: (location: string) => {
            dispatch(push(location));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);
