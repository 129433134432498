import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { loadAlerts, showAddAlertModal } from '../../redux/actions/alert/alert';
import AddAlertModal from '../../components/AddAlertModal/AddAlertModal';
import { loadHirarchy } from '../../redux/actions/hirarchy/hirarchy';
import AlertItem from '../../components/AlertItem/AlertItem';
import LoadingSpinner from '../../components/Loading/Loading';
import './Alerts.scss';

class Alerts extends Component<{ push: any, showAddAlertModal: any, loadAlerts: any, alerts: any, loadHirarchy: any, loading: boolean }, {}> {

  /**
   * Component did load.
   */
  componentDidMount() {
    this.props.loadAlerts();
    this.props.loadHirarchy();
  }

  /**
   * Renders a loading component.
   */
  renderLoading() {
    return (
      <div className="p-20">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  /**
   * Render the main content.
   */
  renderMainPage() {
    return (<div>
      <div className="up-header">
        Active Alerts
        </div>
      <div>
        { this.props.alerts.length <= 0 && <div className="no-data-added-yet">No alerts added yet.</div> }
        {this.props.alerts.map((alert: any, index: any) => {
          return (<AlertItem key={ index } alert={alert}></AlertItem>);
        })}
      </div>
      <div className="p-10">
        <a href="#" className="button button--primary" onClick={() => {
          this.props.showAddAlertModal({
            new: true
          });
        }}>Add new alert</a>
      </div>
    </div>);
  }

  /**
   * Main render method
   */
  render() {
    return (
      <div className="HomePage page">
        <div className="header-box">
          <div className="title">
            My Alerts
            </div>
        </div>
        {this.props.loading ? this.renderLoading() : this.renderMainPage()}
        <AddAlertModal></AddAlertModal>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    alerts: state.alert.alerts,
    loading: state.alert.loading
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    push: (path: any) => {
      dispatch(push(path));
    },
    loadAlerts: () => {
      dispatch(loadAlerts());
    },
    loadHirarchy: () => {
      dispatch(loadHirarchy());
    },
    showAddAlertModal: (data: any) => {
      dispatch(showAddAlertModal(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alerts);
