import { faTachometerAlt, faChartBar, faWrench } from '@fortawesome/free-solid-svg-icons';

var startDate = new Date();
startDate.setHours(startDate.getHours() - 1);

export default [{
    value: "current",
    name: "CURRENT",
    icon: faTachometerAlt,
    configuration: [{
        type: "tachometer",
        title: "Solar Power",
        dataType: "modulepwr",
        conversionFactor: 1,
        unit: "W",
        from: new Date(),
        to: new Date(),
        min: 0,
        max: 200,
        segmentColors: {
            first: "red",
            second: "orange",
            third: "green"
        },
        segmentValues: {
            first: 66,
            second: 132,
            third: 200
        }
    }, {
        type: "tachometer",
        title: "Inverter Power",
        dataType: "invcur",
        conversionFactor: 0.225,
        unit: "W",
        from: new Date(),
        to: new Date(),
        min: 0,
        max: 200,
        segmentColors: {
            first: "green",
            second: "orange",
            third: "red"
        },
        segmentValues: {
            first: 66,
            second: 132,
            third: 200
        }
    },  {
        type: "tachometer",
        title: "Battery State of Charge",
        dataType: "battsoc",
        conversionFactor: 1,
        unit: "%",
        from: new Date(),
        to: new Date(),
        min: 0,
        max: 100,
        segmentColors: {
            first: "red",
            second: "orange",
            third: "green"
        },
        segmentValues: {
            first: 20,
            second: 50,
            third: 100
        }
    }, {
        type: "tachometer",
        title: "Power-Blox Temperature",
        dataType: "pcbtemp",
        conversionFactor: 1,
        unit: "°C",
        from: new Date(),
        to: new Date(),
        min: 0,
        max: 100,
        segmentColors: {
            first: "green",
            second: "orange",
            third: "red"
        },
        segmentValues: {
            first: 70,
            second: 75,
            third: 100
        }
    }, {
        type: "tachometer",
        title: "MPP Temperature",
        dataType: "mppttemp",
        conversionFactor: 1,
        unit: "°C",
        from: new Date(),
        to: new Date(),
        min: 0,
        max: 100,
        segmentColors: {
            first: "green",
            second: "orange",
            third: "red"
        },
        segmentValues: {
            first: 70,
            second: 75,
            third: 100
        }
    }, {
        type: "tachometer",
        title: "Inverter Temperature",
        dataType: "invtemp",
        conversionFactor: 1,
        unit: "°C",
        from: new Date(),
        to: new Date(),
        min: 0,
        max: 100,
        segmentColors: {
            first: "green",
            second: "orange",
            third: "red"
        },
        segmentValues: {
            first: 70,
            second: 75,
            third: 100
        }
    }, {
        type: "tachometer",
        title: "Battery Temperature",
        dataType: "batttemp",
        conversionFactor: 1,
        unit: "°C",
        from: new Date(),
        to: new Date(),
        min: 0,
        max: 100,
        segmentColors: {
            first: "green",
            second: "orange",
            third: "red"
        },
        segmentValues: {
            first: 45,
            second: 60,
            third: 100
        }
    }]
}, {
    value: "stats",
    name: "STATS",
    icon: faChartBar,
    configuration: [{
        type: "chart",
        title: "Solar Power",
        dataType: "modulepwr",
        conversionFactor: 1,
        unit: "W",
        from: startDate,
        to: new Date(),
        min: 0,
        max: 250
    }, {
        type: "chart",
        title: "Inverter Power",
        dataType: "invcur",
        conversionFactor: 0.225,
        unit: "W",
        from: startDate,
        to: new Date(),
        min: 0,
        max: 250
    }, {
        type: "chart",
        title: "Battery State of Charge",
        dataType: "battsoc",
        conversionFactor: 1,
        unit: "%",
        from: startDate,
        to: new Date(),
        min: 0,
        max: 100
    }, {
        type: "chart",
        title: "Power-Blox Temperature",
        dataType: "pcbtemp",
        conversionFactor: 1,
        unit: "°C",
        from: startDate,
        to: new Date(),
        min: 0,
        max: 100
    }, {
        type: "chart",
        title: "MPP Temperature",
        dataType: "mppttemp",
        conversionFactor: 1,
        unit: "°C",
        from: startDate,
        to: new Date(),
        min: 0,
        max: 100
    }, {
        type: "chart",
        title: "Inverter Temperature",
        dataType: "invtemp",
        conversionFactor: 1,
        unit: "°C",
        from: startDate,
        to: new Date(),
        min: 0,
        max: 100
    }, {
        type: "chart",
        title: "Battery Temperature",
        dataType: "batttemp",
        conversionFactor: 1,
        unit: "°C",
        from: startDate,
        to: new Date(),
        min: 0,
        max: 100
    }]
}, {
    value: "custom",
    name: "CUSTOM",
    icon: faWrench,
    configuration: []
}];