import { LOADING_DEVICE, DEVICE_LOADED, SET_DEVICE_CONTENTCONFIGURATION, SET_DEVICE_CURRENT_CONFIG_TAB, LOADED_DATA_FOR_PROPERTY, SET_DEVICE_TIMEINTERVAL, LOADED_HISTORY_DATA_FOR_PROPERTY, SHOW_DATE_PICKER_MODAL, SET_CUSTOM_CONFIGURATION } from "../../actions/device/device";
import DeviceConfigFilterConfig from "../../../components/DeviceConfigFilter/DeviceConfigFilterConfig";

const initialState = {
    loading: true,
    currentDevice: null,
    contentConfiguration: DeviceConfigFilterConfig[0].configuration,
    currentTimeInterval: 3600,
    currentConfigTab: "current",
    historyData: {},
    currentConfiguration: null,
    customConfigurationEdit: null,
    data: {},
    customConfigIndex: null
};

export default function device(state = initialState, action: any) {
    if (action.type === LOADING_DEVICE) {
        return Object.assign({}, state, {
            loading: true
        });
    }
    if (action.type === DEVICE_LOADED) {
        return Object.assign({}, state, {
            loading: false,
            currentDevice: action.device
        });
    }
    if (action.type === SET_DEVICE_CONTENTCONFIGURATION) {
        return Object.assign({}, state, {
            contentConfiguration: action.contentConfiguration
        });
    }
    if (action.type === SHOW_DATE_PICKER_MODAL) {
        return Object.assign({}, state, {
            currentConfiguration: action.config
        });
    }
    if (action.type === SET_DEVICE_CURRENT_CONFIG_TAB) {
        return Object.assign({}, state, {
            currentConfigTab: action.tab
        });
    }
    if (action.type === SET_DEVICE_TIMEINTERVAL) {
        return Object.assign({}, state, {
            currentTimeInterval: action.currentTimeInterval
        });
    }
    if (action.type === SET_CUSTOM_CONFIGURATION) {
        return Object.assign({}, state, {
            customConfigIndex: action.configIndex
        });
    }
    if (action.type === LOADED_DATA_FOR_PROPERTY) {
        let data: any = state.data;
        data[action.property] = action.data;
        return Object.assign({}, state, {
            data: data
        });
    }
    if (action.type === LOADED_HISTORY_DATA_FOR_PROPERTY) {
        let historyData: any = state.historyData;
        historyData[action.property] = action.data;
        return Object.assign({}, state, {
            historyData: historyData
        });
    }
    return state;
}  