import { Client } from "../../../services/client";
import { push } from "connected-react-router";
import { logout, checkAdminState } from "../user/user";
import Swal from "sweetalert2";

export const HIRARCHY_STARTED_LOADING = 'HIRARCHY_STARTED_LOADING';
export const hirarchyStartedLoading = () => {
    return {
        type: HIRARCHY_STARTED_LOADING
    }
}

export const HIRARCHY_LOADED = 'HIRARCHY_LOADED';
export const hirarchyLoaded = (rootNode: any) => {
    return {
        type: HIRARCHY_LOADED,
        rootNode
    }
}

export const USER_PERMISSIONS_LOADED = 'USER_PERMISSIONS_LOADED';
export const userPermissionsLoaded = (permissions: any) => {
    return {
        type: USER_PERMISSIONS_LOADED,
        permissions
    }
}

export const GATEWAYS_STARTED_LOADING = 'GATEWAYS_STARTED_LOADING';
export const gatewaysStartedLoading = () => {
    return {
        type: GATEWAYS_STARTED_LOADING
    }
}

export const GATEWAYS_LOADED = 'GATEWAYS_LOADED';
export const gatewaysLoaded = (gateways: any) => {
    return {
        type: GATEWAYS_LOADED,
        gateways
    }
}

export const PUSH_GROUP = 'PUSH_GROUP';
export const pushGroup = (group: any) => {
    return {
        type: PUSH_GROUP,
        group
    }
}

export const POP_GROUP = 'POP_GROUP';
export const popGroup = () => {
    return {
        type: POP_GROUP
    }
}

export const UPDATING_GROUP = 'UPDATING_GROUP';
export const updatingGroup = (data: any, groupId: string) => {
    return {
        type: UPDATING_GROUP,
        data,
        groupId
    }
}

export const GROUP_UPDATED = 'GROUP_UPDATED';
export const groupUpdated = (data: any, groupId: string) => {
    return {
        type: GROUP_UPDATED,
        data,
        groupId
    }
}

export const UPDATING_DEVICE = 'UPDATING_DEVICE';
export const updatingDevice = (data: any, deviceId: string) => {
    return {
        type: UPDATING_DEVICE,
        data,
        deviceId
    }
}

export const UPDATING_GATEWAY = 'UPDATING_GATEWAY';
export const updatingGateway = (data: any, gatewayId: string) => {
    return {
        type: UPDATING_GATEWAY,
        data,
        gatewayId
    }
}

export const DEVICE_UPDATED = 'DEVICE_UPDATED';
export const deviceUpdated = (data: any, deviceId: string) => {
    return {
        type: DEVICE_UPDATED,
        data,
        deviceId
    }
}

export const GATEWAY_UPDATED = 'GATEWAY_UPDATED';
export const gatewayUpdated = (data: any, gatewayId: string) => {
    return {
        type: GATEWAY_UPDATED,
        data,
        gatewayId
    }
}

export const ADDING_GROUP = 'ADDING_GROUP';
export const addingGroup = () => {
    return {
        type: ADDING_GROUP
    }
}

export const GROUP_ADDED = 'GROUP_ADDED';
export const groupAdded = (group: any, addToGroupWithId: any) => {
    return {
        type: GROUP_ADDED,
        group,
        addToGroupWithId
    }
}

export const ADDING_DEVICE = 'ADDING_DEVICE';
export const addingDevice = () => {
    return {
        type: ADDING_DEVICE
    }
}

export const DEVICE_ADDED = 'DEVICE_ADDED';
export const deviceAdded = (device: any, addToGroupWithId: any) => {
    return {
        type: DEVICE_ADDED,
        device,
        addToGroupWithId
    }
}

export const GATEWAY_ADDED = 'GATEWAY_ADDED';
export const gatewayAdded = (gateway: any) => {
    return {
        type: GATEWAY_ADDED,
        gateway,
    }
}

export const SET_ADD_TO_GROUP = 'SET_ADD_TO_GROUP';
export const setAddToGroup = (groupId: any) => {
    return {
        type: SET_ADD_TO_GROUP,
        groupId
    }
}

export const SHOW_DEVICE_DETAILS = 'SHOW_DEVICE_DETAILS';
export const showDeviceDetails = (device: any) => {
    return {
        type: SHOW_DEVICE_DETAILS,
        device
    }
}

export const SHOW_GATEWAY_DETAILS = 'SHOW_GATEWAY_DETAILS';
export const showGatewayDetails = (gateway: any) => {
    return {
        type: SHOW_GATEWAY_DETAILS,
        gateway
    }
}

export const SHOW_GROUP_DETAILS = 'SHOW_GROUP_DETAILS';
export const showGroupDetails = (group: any) => {
    return {
        type: SHOW_GROUP_DETAILS,
        group
    }
}

export const loadHirarchy = () => {
    return (dispatch: any) => {
        dispatch(checkAdminState());
        dispatch(hirarchyStartedLoading());

        Client.hirarchy().then((data: any) => {
            if (data) {
                dispatch(hirarchyLoaded(data));
            }
        }).catch((error: any) => {
            if (error && error.response && error.response.data && error.response.data.code === 401) {
                dispatch(logout());
            }
        });
    }
}

export const loadUserPermission = () => {
    return (dispatch: any) => {
        return Client.userrelation().then((data: any) => {
            if (data) {
                dispatch(userPermissionsLoaded(data));
            }
        }).catch((error: any) => {
        });
    }
}

export const loadGateways = () => {
    return (dispatch: any) => {
        dispatch(gatewaysStartedLoading());

        Client.gateways().then((data: any) => {
            if (data) {
                dispatch(gatewaysLoaded(data));
            }
        }).catch((error: any) => {
            if (error && error.response && error.response.data && error.response.data.code === 401) {
                dispatch(logout());
            }
        });
    }
}

export const addGroupToGroup = (data: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(addingGroup());
        let addToGroupWithId = getState().hirarchy.addGroupToGroupId;

        Client.addGroupToGroup(data, addToGroupWithId).then((group) => {
            dispatch(groupAdded(group, addToGroupWithId));
            dispatch(loadHirarchy());
            dispatch(push("overview"));
        }).catch((error: Error) => {
            Swal.fire({
                title: 'Could not update Group',
                text: 'The Group could not be updated. Maybe you are missing Permissions to this Group',
                type: 'error',
                confirmButtonColor: '#ed1c24',
                showCancelButton: false,
                confirmButtonText: 'OK',
            });
        });
    }
}

export const addBlockToGroup = (macAddress: string, groupId: string, blockName: string, gatewayToAddTo: string) => {
    return (dispatch: any) => {
        dispatch(addingDevice());

        Client.addBlockToGroup(macAddress, groupId, blockName, gatewayToAddTo).then((device) => {
            dispatch(deviceAdded(device, groupId));
            dispatch(loadHirarchy());
            dispatch(push("overview"));
        }).catch((error: any) => {
            if (error.response.status == 308) {
                Swal.fire({
                    title: 'Device already exists',
                    text: 'The device already exists. Press OK to view the existing Device!',
                    type: 'info',
                    confirmButtonColor: '#ed1c24',
                    showCancelButton: true,
                    confirmButtonText: 'OK',
                }).then((response: any) => {
                    if (response.value) {
                        dispatch(push("device/" + error.response.data.detail));
                    }
                });
            } else {
                Swal.fire({
                    title: 'Device not found',
                    text: 'The device could not be found. Please make sure that the device is turned on and already sending data to the cloud!',
                    type: 'error',
                    confirmButtonColor: '#ed1c24',
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                });
            }
        });
    }
}

export const addGateway = (macAddress: string, blockName: string) => {
    return (dispatch: any) => {
        dispatch(addingDevice());

        Client.addGateway(macAddress, blockName).then((gateway) => {
            dispatch(gatewayAdded(gateway));
            dispatch(loadHirarchy());
            dispatch(push("gateways"));
        }).catch((error: Error) => {
            Swal.fire({
                title: 'Gateway not found',
                text: 'The Gateway could not be found. Please make sure that the Gateway is turned on and already sending data to the cloud!',
                type: 'error',
                confirmButtonColor: '#ed1c24',
                showCancelButton: false,
                confirmButtonText: 'OK',
            });
        });
    }
}

export const updateGroup = (data: any, groupId: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(updatingGroup(data, groupId));

        Client.updateGroup(data, groupId).then((group: any) => {
            dispatch(groupUpdated(data, groupId));

            // Reload page in order to preserve the state & structure correctly when groups have moved.
            if (data.parent) {
                window.location.reload();
            }
        }).catch((error: Error) => {
            Swal.fire({
                title: 'Could not update Group',
                text: 'The Group could not be updated. Maybe you are missing Permissions to this Group',
                type: 'error',
                confirmButtonColor: '#ed1c24',
                showCancelButton: false,
                confirmButtonText: 'OK',
            });
        });
    }
}

export const updateDevice = (data: any, deviceId: string) => {
    return (dispatch: any) => {
        dispatch(updatingDevice(data, deviceId));

        Client.updateDevice(data, deviceId).then((device: any) => {
            dispatch(deviceUpdated(data, deviceId));

            // Reload page in order to preserve the state & structure correctly when groups have moved.
            if (data.devicegroup) {
                window.location.reload();
            }
        }).catch((error: Error) => {
            Swal.fire({
                title: 'Could not update Device',
                text: 'The Device could not be updated. Maybe you are missing Permissions to this Device',
                type: 'error',
                confirmButtonColor: '#ed1c24',
                showCancelButton: false,
                confirmButtonText: 'OK',
            });
        });
    }
}

export const updateGateway = (data: any, gatewayId: string) => {
    return (dispatch: any) => {
        dispatch(updatingGateway(data, gatewayId));

        Client.updateGateway(data, gatewayId).then((device: any) => {
            dispatch(gatewayUpdated(data, gatewayId));
        }).catch((error: Error) => {
            Swal.fire({
                title: 'Could not update Gateway',
                text: 'The Gateway could not be updated. Maybe you are missing Permissions to this Gateway',
                type: 'error',
                confirmButtonColor: '#ed1c24',
                showCancelButton: false,
                confirmButtonText: 'OK',
            });
        });
    }
}

export const removeDevice = (deviceId: string) => {
    return (dispatch: any) => {
        dispatch(hirarchyStartedLoading());
        Client.removeDevice(deviceId).then((device: any) => {
            window.location.reload();
        }).catch((error: Error) => {
            Swal.fire({
                title: 'Could not delete Device',
                text: 'The Device could not be deleted. Maybe you are missing Permissions to this Device',
                type: 'error',
                confirmButtonColor: '#ed1c24',
                showCancelButton: false,
                confirmButtonText: 'OK',
            });
        });
    }
}

export const removeGateway = (gatewayId: string) => {
    return (dispatch: any) => {
        dispatch(hirarchyStartedLoading());
        Client.removeGateway(gatewayId).then((device: any) => {
            window.location.reload();
        }).catch((error: Error) => {
            Swal.fire({
                title: 'Could not delete Gateway',
                text: 'The Gateway could not be deleted. Maybe you are missing Permissions to this Gateway',
                type: 'error',
                confirmButtonColor: '#ed1c24',
                showCancelButton: false,
                confirmButtonText: 'OK',
            });
        });
    }
}

export const removeGroup = (groupId: string) => {
    return (dispatch: any) => {
        dispatch(hirarchyStartedLoading());
        Client.removeGroup(groupId).then((group: any) => {
            window.location.reload();
        }).catch((error: Error) => {
            Swal.fire({
                title: 'Could not delete Group',
                text: 'The Group could not be deleted. Maybe you are missing Permissions to this Group',
                type: 'error',
                confirmButtonColor: '#ed1c24',
                showCancelButton: false,
                confirmButtonText: 'OK',
            });
        });
    }
}