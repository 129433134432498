import { USER_LOGIN_STARTED, USER_LOGIN_FAILED, USER_LOGIN_SUCCEEDED, USER_REGISTER_SUCCEEDED, USER_REGISTER_STARTED, USER_REGISTER_FAILED, SET_ADMIN_STATE, ADMIN_USER_DATA } from "../../actions/user/user";

const initialState = {
    loading: false,
    isAdmin: false,
    loadingRegister: false, 
    loginError: null,
    registerError: null,
    username: "",
    password: "",
    adminUserData: []
};
  
export default function user(state = initialState, action: any) {
    if (action.type === USER_LOGIN_STARTED) {
        return Object.assign({}, state, {
            loading: true,
            loginError: null,
            username: action.username,
            password: action.password
        });
    }
    if (action.type === USER_LOGIN_FAILED) {
        return Object.assign({}, state, {
            loading: false,
            loginError: action.error
        });
    }
    if (action.type === ADMIN_USER_DATA) {
        return Object.assign({}, state, {
            adminUserData: action.data
        });
    }
    if (action.type === USER_LOGIN_SUCCEEDED) {
        return Object.assign({}, state, {
            loading: false
        });
    }
    if (action.type === SET_ADMIN_STATE) {
        return Object.assign({}, state, {
            isAdmin: action.isAdmin
        });
    }
    if (action.type === USER_REGISTER_STARTED) {
        return Object.assign({}, state, {
            loadingRegister: true,
            registerError: null
        });
    }
    if (action.type === USER_REGISTER_FAILED) {
        return Object.assign({}, state, {
            loadingRegister: false,
            registerError: action.error
        });
    }
    if (action.type === USER_REGISTER_SUCCEEDED) {
        return Object.assign({}, state, {
            loadingRegister: false
        });
    }
    return state;
}  