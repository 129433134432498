import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import user from "./user/user";
import hirarchy from "./hirarchy/hirarchy";
import device from "./device/device";
import alert from "./alert/alert";

export default (historyReducer: any) => combineReducers({
  router: connectRouter(historyReducer),
  user: user,
  hirarchy: hirarchy,
  device: device,
  alert: alert
});