import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import './DeviceSettingsModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faNetworkWired, faFolderOpen, faPen, faCube, faCubes } from '@fortawesome/free-solid-svg-icons';
import { showGroupDetails, updateGroup, removeDevice, showDeviceDetails, updateDevice } from '../../redux/actions/hirarchy/hirarchy';
import Select from 'react-select';

class DeviceSettingsModal extends Component<{ showModal: boolean, flatGroupList: any[], showDeviceDetails: any, deviceDetails: any, updateDevice: any, removeDevice: any }, { deviceName: string, groupIdVal: any, groupId: any }> {
    state = {
        deviceName: "",
        groupIdVal: null,
        groupId: "",
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.deviceName === "" && this.state.deviceName === "" && this.props.deviceDetails && this.props.deviceDetails.name) {
            this.setState({
                deviceName: this.props.deviceDetails.name
            });
        }
    }

    close() {
        this.setState({
            deviceName: ""
        });
        this.props.showDeviceDetails(null);
    }

    save() {
        if (!this.props.deviceDetails.id || !this.state.deviceName) {
            return;
        }

        let data: any = {
            name: this.state.deviceName
        };

        
        // Move group only if parent is set.
        if (this.state.groupId != "") {
            data["devicegroup"] = [`/api/device_groups/${this.state.groupId}`];
        }

        this.props.updateDevice(data, this.props.deviceDetails.id);
        this.close();
    }

    remove() {
        if (!confirm("Are you sure you want to remove this device?")) {
            return;
        }

        this.props.removeDevice(this.props.deviceDetails.id);
        this.close();
    }

    render() {
        return (
            <Modal
                isOpen={this.props.showModal}
                contentLabel="Update device"
                ariaHideApp={false}
            >
                {this.props.deviceDetails && <div className="modal">
                    <div className="modal-header">
                        <div className="btn btn--black">
                            <div className="icon">
                                <FontAwesomeIcon icon={ faCube } />
                            </div>
                        </div>
                        <div className="title">{this.props.deviceDetails && this.props.deviceDetails.name}</div>
                        <div className="btn" onClick={() => {
                            this.close();
                        }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="edit-container">
                            <div className="inputForm inputForm--lined">
                                <span>Rename</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={faPen} />
                                    <input type="text" value={this.state.deviceName} onChange={(event: any) => {
                                        this.setState({ deviceName: event.target.value });
                                    }}></input>
                                </div>
                            </div>
                            <div className="inputForm inputForm--lined">
                                <span>Move to Group</span>
                                <div className="inputForm-container">
                                    <div style={{ marginRight: '13px' }}><FontAwesomeIcon icon={faCubes} /></div>
                                    <div style={{flexGrow: 1}}>
                                        <Select
                                            onChange={(selectedOption) => {
                                                this.setState({
                                                    groupIdVal: selectedOption,
                                                    groupId: (selectedOption as any).value
                                                });
                                            }}
                                            value={this.state.groupIdVal}
                                            options={this.props.flatGroupList}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                border: 'none',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eee',
                                                    primary: '#ed1c24',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="doneBtn">
                            <a href="#" className="button" onClick={() => {
                                this.remove();
                            }}>Remove device</a>
                            <a href="#" className="button button--primary" onClick={() => {
                                this.save();
                            }}>Save</a>
                        </div>
                    </div>
                </div>}
            </Modal>
        );
    }
}

/**
 * Helper to create a flat group list with an excluding group.
 * @param group 
 */
function flatGroupListWithoutGroup(rootGroup: any, excludeGroup: any): any {
    let groupsArr: any[] = [];

    if (rootGroup.id === excludeGroup.id) {
        return groupsArr;
    }
    
    if (rootGroup.id != null) {
        groupsArr.push({
            value: rootGroup.id,
            label: rootGroup.name,
            devices: rootGroup.devices
        });
    }

    if (rootGroup.childs && rootGroup.childs.length > 0) {
        for (var i = 0; i < rootGroup.childs.length; i++) {

            let result = flatGroupListWithoutGroup(rootGroup.childs[i], excludeGroup);
            if (result) {
                groupsArr.push(...result);
            }
        }
    }
    return groupsArr;
}

const mapStateToProps = (state: any) => {
    return {
        showModal: (state.hirarchy.deviceForEditModal !== null),
        deviceDetails: state.hirarchy.deviceForEditModal,
        flatGroupList: state.hirarchy.flatGroupList,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeDevice: (deviceId: string) => {
            dispatch(removeDevice(deviceId));
        },
        updateDevice: (data: any, deviceId: string) => {
            dispatch(updateDevice(data, deviceId));
        },
        showDeviceDetails: (config: any) => {
            dispatch(showDeviceDetails(config));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceSettingsModal);
