import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateGroup, showGatewayDetails } from '../../redux/actions/hirarchy/hirarchy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPen, faCube, faTrash, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import * as classnames from 'classnames';
import dotsIcon from '../../assets/icons/dots.svg';
import { push } from 'connected-react-router';
import DeviceItem from '../DeviceItem/DeviceItem';

class GatewayItem extends Component<{ gateway: any, permissionList: any, push: any, updateGroup: any, showGatewayDetails: any }, { toggleOptions: boolean, deviceName: string }> {
    state = {
        toggleOptions: false,
        deviceName: ""
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.deviceName === "" && this.state.deviceName === "") {
            this.setState({
                deviceName: this.props.gateway.name
            });
        }
    }

    hasEditPremissions() {
        let permission = this.props.permissionList.find((per: any) => { return per.userId == this.props.gateway.owner.id});
        if (permission != null) {
            return permission.permission >= 2;
        } else {
            return false;
        }
    }

    render() {
        return (
            <div className="gatewaybox">
                <div className="row">
                    <div className="icon">
                        <FontAwesomeIcon icon={faNetworkWired} />
                    </div>
                    <div className="title">
                        {this.props.gateway.name}
                    </div>
                    { this.hasEditPremissions() &&
                    <div className={classnames({
                        "action-button": true,
                        "action-button--active": this.state.toggleOptions
                    })} onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        this.props.showGatewayDetails(this.props.gateway);
                    }}>
                        <img className="more-icon" src={dotsIcon} />
                    </div> }
                </div>
                <div className="child-container">
                    {this.props.gateway.devices && this.props.gateway.devices.map((device: any) => {
                        return (
                            <DeviceItem device={device} key={device.name}></DeviceItem>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        gateways: state.hirarchy.gateways, // so component updates
        permissionList: state.hirarchy.permissionList,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateGroup: (data: any, groupId: string) => {
            dispatch(updateGroup(data, groupId));
        },
        push: (route: any) => {
            dispatch(push(route));
        },
        showGatewayDetails: (config: any) => {
            dispatch(showGatewayDetails(config));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GatewayItem);
