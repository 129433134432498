export default [{
    value: "chargecur",
    label: "Charge Current",
    unit: "A",
    conversionFactor: 0.001
}, {
    value: "dischargecur",
    label: "Discharge Current",
    unit: "A",
    conversionFactor: 0.001
}, {
    value: "battmeasvol",
    label: "Battery Voltage",
    unit: "V",
    conversionFactor: 0.001
}, {
    value: "batttemp",
    label: "Battery Temperature",
    unit: "°C",
    conversionFactor: 1
}, {
    value: "pcbtemp",
    label: "Power Blox Temperature",
    unit: "°C",
    conversionFactor: 1
}, {
    value: "battsoc",
    label: "Battery State of Charge",
    unit: "%",
    conversionFactor: 1
}, {
    value: "modulecur",
    label: "Module Current",
    unit: "A",
    conversionFactor: 0.001
}, {
    value: "modulevol",
    label: "Module Voltage",
    unit: "V",
    conversionFactor: 0.001
}, {
    value: "mppttemp",
    label: "MPP Temperature",
    unit: "°C",
    conversionFactor: 1
}, {
    value: "modulepwr",
    label: "Solar Power",
    unit: "W",
    conversionFactor: 1
}, {
    value: "invcur",
    label: "Inverter Current",
    unit: "A",
    conversionFactor: 0.001
},{
    value: "invfreq",
    label: "Inverter Frequency",
    unit: "Hz",
    conversionFactor: 1
}];