import React, { Component } from 'react';
import './App.scss';

// Redux
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

// Pages
import LoginPage from './pages/Login/Login';
import RegisterPage from './pages/Register/Register';
import HomePage from './pages/Home/Home';
import AddGroup from './pages/AddGroup/AddGroup';
import AddDevice from './pages/AddDevice/AddDevice';
import DeviceInfo from './pages/DeviceInfo/DeviceInfo';
import { Client } from './services/client';
import Footer from './components/Footer/Footer';
import Alerts from './pages/Alerts/Alerts';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Admin from './pages/Admin/Admin';
import AddGateway from './pages/AddGateway/AddGateway';
import Gateways from './pages/Gateways/Gateways';
import ChangePassword from './pages/ChangePassword/ChangePassword';

class App extends Component<{ history: any }, {}> {
    componentWillMount() {
        Client.injectAuthHeader();
    }

    render() {
        return (
            <div className="app">
                <ConnectedRouter history={this.props.history}>
                    <div>
                        <Switch>
                            <Route path="/" exact component={LoginPage} />
                            <Route path="/forgotpassword" exact component={ForgotPassword} />
                            <Route path="/resetpassword/:token" exact component={ChangePassword} />
                            <Route path="/register" exact component={RegisterPage} />
                            <Route path="/overview" exact component={HomePage} />
                            <Route path="/addgroup" exact component={AddGroup} />
                            <Route path="/alerts" exact component={Alerts} />
                            <Route path="/admin" exact component={Admin} />
                            <Route path="/adddevice" exact component={AddDevice} />
                            <Route path="/addgateway" exact component={AddGateway} />
                            <Route path="/gateways" exact component={Gateways} />
                            <Route path="/device/:id" exact component={DeviceInfo} />
                        </Switch>
                    </div>
                </ConnectedRouter>
                <Footer></Footer>
            </div>
        );
    }
}

export default App;
