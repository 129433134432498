import React, { Component } from 'react';
import './ChartDiagramm.scss';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { loadDeviceHistoryForProperty, showDatePickerModal, setCustomConfigurationIndex } from '../../redux/actions/device/device';
import dotsIcon from '../../assets/icons/dots.svg';
import LoadingSpinner from '../Loading/Loading';
import moment from 'moment';
import 'moment/min/locales';

class ChartDiagramm extends Component<{ config: any, loaded: any, currentConfigTab?: string ,configurationIndex: any, loadDeviceHistoryForProperty: any, setCustomConfigurationIndex: any, data: any, showDatePickerModal: any }, { timeFormat: any }> {
    state = {
        timeFormat: {
            unit: 'day',
            displayFormats: {
                day: 'DD.MM.YYYY'
            }
        }
    };
    private defaultMAX = 100;

    /**
     * Constructor
     * @param props 
     */
    constructor(props: any) {
        super(props);

        let navigator: any = window.navigator;
        let locale = navigator.userLanguage || navigator.language;
        moment.locale(locale);
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        let dateFrom = this.props.config.from;
        let dateTo = this.props.config.to;
        if (this.props.config.customQuery) {
            dateFrom = new Date(this.props.config.from);
            dateFrom.setHours(0,0,0,0);
            dateTo = new Date(this.props.config.to);
            dateTo.setHours(23,59,59,999);
        }

        let diffMins = Math.abs(dateTo.getTime() - dateFrom.getTime()) / 60000;
        if (diffMins <= 1 * 60) {
            this.setState({
                timeFormat: {
                    unit: 'minute',
                    displayFormats: {
                        minute: 'HH:mm'
                    }
                },
            });
        } else if (diffMins <= 24 * 60) {
            this.setState({
                timeFormat: {
                    unit: 'hour',
                    displayFormats: {
                        hour: 'HH:mm'
                    }
                },
            });
        } else {
                this.setState({
                    timeFormat: {
                        unit: 'day',
                        displayFormats: {
                            day: 'DD.MM.YYYY'
                        }
                    }
                });

        }
        this.props.loadDeviceHistoryForProperty(this.props.config.dataType, dateFrom, dateTo);
    }

    /**
     * Generate lines (horizontally)
     * eg 100, 50, 25
     * @param percent 
     */
    generateLine(percent: number) {
        let data = [];
        let fifty = 0;
        if (this.props.config.max) {
            fifty = (this.props.config.max / (100 / percent));
        } else {
            fifty = (this.defaultMAX / (100 / percent));
        }

        for (let i = 0; i < this.props.data.length; i++) {
            data.push({ x: this.props.data[i].x, y: fifty });
        }
        return data;
    }

    /**
     * Render the chart for the given data.
     */
    renderChart() {
        let data = (canvas: any) => {
            const ctx = canvas.getContext("2d");
            const gradient = ctx.createLinearGradient(0, 0, 300, 0);
            gradient.addColorStop(0, '#ed1c24');
            gradient.addColorStop(0.5, '#ed1c24');
            gradient.addColorStop(1, '#ed1c24');

            let newArr = [];
            let labels: any = [];

            if (this.props.data) {
                for (let i = 0; i < this.props.data.length; i++) {
                    let d = this.props.data[i];
                    if (d) {
                        let dateString = d.x;
                        if (d.x) {
                            dateString = moment.unix(d.x);
                        }
                        labels.push(dateString);
                        newArr.push(parseFloat(d.y) * (this.props.config.conversionFactor || 1));
                    }
                }
                // makes sure that the whole 24h range is displayed even without data
                let startTime = new Date(this.props.config.from);
                let endTime = new Date(this.props.config.to);
                if (this.props.currentConfigTab === "custom") {
                    startTime = new Date(this.props.config.from);
                    endTime = new Date(this.props.config.to);
                    startTime.setHours(0,0,0,0);
                    labels.unshift(moment(startTime));
                    newArr.unshift(undefined);

                    endTime.setHours(23,59,59,999);
                    labels.push(moment(endTime));
                    newArr.push(undefined);
                } else {
                    labels.unshift(moment(startTime));
                    newArr.unshift(undefined);

                    labels.push(moment(endTime));
                    newArr.push(undefined);
                }
            }

            let config = {
                labels: labels,
                datasets: [{
                    backgroundColor: 'transparent',
                    data: newArr,
                    pointRadius: 0,
                    borderWidth: 1.5,
                    borderColor: gradient,
                    lineTension: 0
                }],
            };

            return config;
        }

        let options: any = {
            maintainAspectRatio: true,
            legend: false,
            tooltips: {
                mode: 'nearest',
                intersect: false 
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: this.state.timeFormat,
                    gridLines: {
                        display: false,
                        color: "rgba(0, 0, 0, 0)",
                    }
                }],
                yAxes: [{
                    gridLines: {
                        color: "rgba(0, 0, 0, 0.2)",
                        borderDash: [3, 3]
                    },
                    ticks: (this.props.currentConfigTab !==  undefined && this.props.currentConfigTab === "stats") ? {
                        // check if min and max is set in config file (DeviceConfigFilterConfig.ts)
                        min: this.props.config.min !== undefined ? this.props.config.min : 0,
                        max: this.props.config.max !== undefined ? this.props.config.max : 100,
                    } : {
                        // fallback for custom queries
                        // custom queries have auto-scale enabled
                        beginAtZero: false
                    }
                }]
            }
        }

        return (
            <Line
                data={data}
                width={100}
                height={50}
                options={options}
            />);
    }

    /**
     * Render method.
     */
    render() {
        if (!this.props.loaded) {
            return (<div>
                <LoadingSpinner></LoadingSpinner>
            </div>);
        }
        return (
            <div className="chartdiagramm-container">
                <div className="chartdiagramm-header">
                    <div className="chartdiagramm-title">{this.props.config.title}</div>
                    <div className="chartdiagramm-btn" onClick={() => {
                        if (this.props.config.customQuery) {
                            this.props.setCustomConfigurationIndex(this.props.configurationIndex);
                        } else {
                            this.props.showDatePickerModal({ open: true });
                        }
                    }}>
                        <img src={dotsIcon} />
                    </div>
                </div>
                <div className="chartdiagramm-body">
                    { (this.props.data.length <= 0) ? <div className="no-data-available">No data available.</div> : this.renderChart()}
                </div>
                <div className="chartdiagramm-unit">{this.props.config.unit}</div>
            </div>
        );
    }
}

const mapStateToProps = (state: any, props: any) => {
    let data = [];
    let loaded = false;

    if (state.device.historyData[props.config.dataType]) {
        data = state.device.historyData[props.config.dataType];
        loaded = true;
    }
    return {
        currentConfigTab: state.device.currentConfigTab,
        data: data,
        loaded: loaded
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadDeviceHistoryForProperty: (property: string, from: Date, to: Date) => {
            dispatch(loadDeviceHistoryForProperty(property, from, to));
        },
        showDatePickerModal: (config: any) => {
            dispatch(showDatePickerModal(config));
        },
        setCustomConfigurationIndex: (configIndex: any) => {
            dispatch(setCustomConfigurationIndex(configIndex));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChartDiagramm);