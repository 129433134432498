import { Client } from "../../../services/client";

export const LOAD_ALERTS = 'LOAD_ALERTS';
export const loadingAlerts = () => {
    return {
        type: LOAD_ALERTS
    }
}

export const SHOW_ADD_ALERT_MODAL = 'SHOW_ADD_ALERT_MODAL';
export const showAddAlertModal = (addAlertModal: any[]) => {
    return {
        type: SHOW_ADD_ALERT_MODAL,
        addAlertModal
    }
}

export const ALERTS_LOADED = 'ALERTS_LOADED';
export const alertsLoaded = (alerts: any[]) => {
    return {
        type: ALERTS_LOADED,
        alerts
    }
}

export const saveAlert = (data: any) => {
    return (dispatch: any) => {
        dispatch(loadingAlerts());
        Client.saveAlert(data).then((data: any) => {
            dispatch(loadAlerts());
        }).catch((error: Error) => {
            dispatch(loadAlerts());
        });
    }
}

export const deleteAlert = (actionId: any) => {
    return (dispatch: any) => {
        dispatch(loadingAlerts());
        Client.deleteAlert(actionId).then((data: any) => {
            dispatch(loadAlerts());
        }).catch((error: Error) => {
            dispatch(loadAlerts());
        });
    }
}

export const loadAlerts = () => {
    return (dispatch: any) => {
        dispatch(loadingAlerts());

        Client.alerts().then((data: any) => {
            dispatch(alertsLoaded(data));
        }).catch((error: Error) => {
            dispatch(alertsLoaded([]));
        });
    }
}