import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSortAlphaDown, faFolderOpen, faPlus, faSignOutAlt, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import { loadHirarchy, pushGroup, popGroup, setAddToGroup, showGroupDetails, loadUserPermission } from '../../redux/actions/hirarchy/hirarchy';
import GroupItem from '../../components/GroupItem/GroupItem';
import LoadingSpinner from '../../components/Loading/Loading';
import { push } from 'connected-react-router';
import DeviceItem from '../../components/DeviceItem/DeviceItem';
import GroupSettingsModal from '../../components/GroupSettingsModal/GroupSettingsModal';
import { logout, checkAdminState } from '../../redux/actions/user/user';
import backIcon from '../../assets/icons/back.svg';
import DeviceSettingsModal from '../../components/DeviceSettingsModal/DeviceSettingsModal';
import dotsIcon from '../../assets/icons/dotsRed.svg';

class HomePage extends Component<{ loadHirarchy: any, loadUserPermission: any, showGroupDetails: any, setAddToGroup: any, logout: any, popGroup: any, currentNode: any, loading: boolean, currentNodeIsRootNode: boolean, push: any }, {}> {
    state = {
        username: "",
        password: "",
        sort: "down"
    };

    componentDidMount() {
        this.props.loadHirarchy();
        this.props.loadUserPermission();
    }

    renderLoading() {
        return (
            <div>
                <div className="header-box">
                    <div className="title">
                        My Blox
                    </div>
                </div>
                <div>
                    <div className="p-20">
                        <LoadingSpinner></LoadingSpinner>
                    </div>
                </div>
            </div>
        );
    }

    sort(a: any, b: any) {
        if (this.state.sort === 'down') {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
        } else {
            if (a.name < b.name) { return 1; }
            if (a.name > b.name) { return -1; }
        }
        return 0;
    }

    sortGroupsAndGateways() {
        if (!(this.props.currentNode.childs && this.props.currentNode.childs.length > 0)) {
            return {
                gateways: [],
                childs: []
            };
        }

        let gateways: any[] = [];
        let groups: any[] = [];

        this.props.currentNode.childs.forEach((element: any) => {
            if (element.id != null) {
                groups.push(element);
            }
        });

        return {
            gateways: gateways,
            childs: groups
        };
    }

    renderSortButton() {
        return (<div className="sort-btn" onClick={() => {
            if (this.state.sort === 'down') {
                this.setState({
                    sort: 'up'
                });
            } else {
                this.setState({
                    sort: 'down'
                });
            }
        }}>
            <FontAwesomeIcon icon={(this.state.sort == 'down') ? faSortAlphaDown : faSortAlphaUp} />
        </div>);
    }

    renderHirarchy() {
        let groupsAndGateways = this.sortGroupsAndGateways();

        return (
            <div>
                <div className="header-box">
                    {this.props.currentNodeIsRootNode ? <div className="action-button action-button--left"></div> : <div className="action-button" onClick={() => {
                        this.props.popGroup();
                    }}>
                        <img src={backIcon} />
                    </div>}
                    <div className="title">
                        {this.props.currentNode.name}
                    </div>
                    {this.props.currentNodeIsRootNode ? <div className="action-button action-button--right" onClick={() => { this.props.logout(); }}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    </div> : (this.props.currentNode.rootOwner != null ? '' : <div className="action-button" onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        this.props.showGroupDetails(this.props.currentNode);
                    }}>
                            <img className="more-icon" src={dotsIcon} />
                        </div>)}
                </div>
                {(groupsAndGateways.childs.length > 0) && <div className="subtitle">Subgroups {this.renderSortButton()} </div>}
                {
                    groupsAndGateways.childs && groupsAndGateways.childs.sort((a: any, b: any) => this.sort(a, b)).map((group: any) => {
                        return (
                            <GroupItem group={group} key={group.name}></GroupItem>
                        );
                    })
                }
                {(groupsAndGateways.gateways.length > 0) && <div className="subtitle">
                    Gateways
          {this.renderSortButton()}
                </div>}
                {
                    groupsAndGateways.gateways && groupsAndGateways.gateways.sort((a: any, b: any) => this.sort(a, b)).map((group: any) => {
                        return (
                            <GroupItem group={group} key={group.name}></GroupItem>
                        );
                    })
                }
                {(this.props.currentNode.devices && this.props.currentNode.devices.length > 0) && <div className="subtitle">
                    Devices
          {this.renderSortButton()}
                </div>}
                {
                    this.props.currentNode.devices && this.props.currentNode.devices.sort((a: any, b: any) => this.sort(a, b)).map((device: any) => {
                        return (
                            <DeviceItem device={device} key={device.name}></DeviceItem>
                        );
                    })
                }
                { this.props.currentNode.id != null &&
                <div className="p-20">
                    <a href="#" className="button" onClick={() => {
                        this.props.setAddToGroup(this.props.currentNode.id);
                        this.props.push("addgroup");
                    }}><FontAwesomeIcon icon={faPlus} /> Add group here</a>
                </div>
                }
                <GroupSettingsModal></GroupSettingsModal>
                <DeviceSettingsModal></DeviceSettingsModal>
            </div>
        );
    }

    render() {
        return (
            <div className="HomePage page">
                {this.props.loading ? this.renderLoading() : this.renderHirarchy()}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.hirarchy.loading,
        currentNode: state.hirarchy.currentNode,
        currentNodeIsRootNode: state.hirarchy.currentNodeIsRootNode,
        permissionList: state.hirarchy.permissionList,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadHirarchy: () => {
            dispatch(loadHirarchy());
        },
        loadUserPermission: () => {
            dispatch(loadUserPermission());
        },
        pushGroup: (group: any) => {
            dispatch(pushGroup(group));
        },
        popGroup: () => {
            dispatch(popGroup());
        },
        setAddToGroup: (groupId: any) => {
            dispatch(setAddToGroup(groupId));
        },
        push: (path: string, state: any) => {
            dispatch(push(path, state))
        },
        logout: () => {
            dispatch(logout());
        },
        showGroupDetails: (config: any) => {
            dispatch(showGroupDetails(config));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage);
